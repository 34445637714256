<template>
  <v-container class="pt-0 mt-0">
    <div class="text-left">
      <v-btn text icon class="pl-0 ml-0 text-left text-start" @click="voltar">
        <v-icon class="pl-0 ml-0 text-left text-start">mdi-arrow-left</v-icon>
      </v-btn>
    </div>

    <div v-if="carregando">
      <v-row class="justify-center px-0">
        <v-col cols="12" class="px-0">
          <v-progress-linear
            color="primary accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      Carregando quartos...
    </div>

    <div v-else-if="hotel">
      <hle-quartos-lista :hotel="hotel" />
    </div>

  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import HleQuartosLista from '../../components/Hoteis/QuartosLista'

export default {
  name: 'QuartosView',
  components: {
    HleQuartosLista
  },
  props: [
    'cidade',
    'hotelId',
    'checkin',
    'checkout',
    'adultos',
    'criancas',
    'echoToken',
    'hotelSignature'
  ],
  data: () => ({
    form: [],
    quarto: false
  }),
  computed: {
    ...mapState({
      carregando: state => state.hotel.carregandoQuartos,
      hotel: state => state.hotel.hotel,
    })
  },
  methods: {
    // ...mapActions([
    //   'carregarHotelAction'
    // ]),
    voltar() {
      this.$router.go(-1)
    }
  },
  created () {

    //console.log('HOTEL QUARTOS VIEW', this.hotel)
    //this.carregarHotelAction(this.hotelId, this.echoToken, this.hotelSignature)

    if(!this.hotel) {
      this.$router.push({
        path: '/home'
      })
    }

  }
}
</script>