<template>
    <div style="margin-top: 25px;">
        <div class="text-center">
            <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
            ></v-progress-circular>
        </div>
        <!-- <v-data-table
            :headers="headers"
            :items="invoices"
            :hide-default-footer="true"
            class="elevation-0"
            v-if="invoices.length"
        > -->
        <v-data-table
            :headers="headers"
            :items="invoices"
            :hide-default-footer="true"
            class="elevation-0"
        >
            <template v-slot:item.status="{ item }">
                <v-chip
                    :color="getColorStatus(item.status)"
                    dark
                    small
                >
                    <strong style="color: black;">{{ item.status }}</strong>
                </v-chip>
            </template>
            <template v-slot:item.actions="{  }">
                <v-icon
                    small
                >
                   mdi-download
                </v-icon>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'Invoice',
        data: () => ({
        loading: true,
        headers: [
          { text: 'Fatura', align: 'start', sortable: false, value: 'pagseguro_code' },
          { text: 'Vencimento', value: 'scheduling_date' },
          { text: 'Situação', value: 'status' },
          { text: '', value: 'actions', sortable: false },
        ],
        invoices: [],
    }),
    methods: {
        getColorStatus (status) {
            switch(status) {
                case 'Pago' : {
                    return 'green'
                }
                case 'Em atraso': {
                    return 'red'
                }
                default: {
                    return 'yellow'
                }
            }
        },
        getPlanInvoices () {
            axios.get(`subscription/${65}`)
            .then(res => {
                this.invoices = res.data[0]?.transactions
                this.loading = false
            })
            .catch(errors => {
                console.log(errors)
                this.loading = false
            })
        }
    },
    created () {
        this.getPlanInvoices()
    }
}
</script>