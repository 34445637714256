<template>
  <v-container class="pt-0 mt-0 pl-0 ml-0 " fluid>

    <div>
      <hle-meu-plano />
    </div>

  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HleMeuPlano from '@/components/Assinatura/MeuPlano'

export default {
  components: {
    HleMeuPlano
  },
  props: [
    // 'cidade',
    // 'checkin',
    // 'checkout',
    // 'adultos',
    // 'criancas'
  ],
  data: () => ({
    // loading: false,
    // form: {},
    // quarto: false
  }),
  computed: {
    ...mapState({
      // carregando: state => state.carregandoHoteis,
      // hoteis: state => state.hoteis,
    })
  },
  methods: {
    ...mapActions([

    ]),
    voltar() {
      this.$router.go(-1)
    }
  },
  created () {
    // console.log('PARAMS',this.$router.params)
    // console.log('CIDADE',this.cidade)

    // this.form.city_code = this.cidade
    // this.form.checkin = this.checkin
    // this.form.checkout = this.checkout
    // this.form.adultos = this.adultos
    // this.form.criancas = this.criancas

    //this.carregarHoteisAction(this.form)

  }
}
</script>