<template>
  <div>
    <v-row justify="space-around">
      <v-col
        md="4"
        class="px-0"
      >
        <h2 class="pt-0 pb-4 text-subtitle-1 text-left font-weight-bold" style="line-height: 1.2">
          <span class="barlow">Meu Perfil</span>
        </h2>
        <div class="pb-4" >
          <v-card class="white grey--text text--darken-4 text-left">
            <v-form v-model="form.valido" ref="form">
              <v-container class="text-left pb-9">
                <v-row>
                  <v-col class="pt-4 pb-2">
                    <div class="text-subtitle-1">
                      <v-icon color="primary" class="pr-1">mdi-account</v-icon>
                      <span class="barlow primary--text font-weight-bold text-uppercase">Dados Pessoais</span>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="pt-0">
                  <v-col cols="12" md="12" class="">
                    <v-text-field
                      v-model="form.nome"
                      :rules="form.rules.nome"
                      dense
                      hide-details
                      label="Nome"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="pt-0">
                  <v-col cols="12" md="12" class="">
                    <v-text-field
                      v-model="form.sobrenome"
                      :rules="form.rules.sobrenome"
                      dense
                      hide-details
                      label="Nome"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="pt-0">
                  <v-col cols="12" md="12" class="">
                    <v-text-field
                      v-model="form.cpf"
                      :rules="form.rules.cpf"
                      dense
                      hide-details
                      label="CPF"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="pt-0">
                  <v-col cols="12" md="12" class="">
                    <v-text-field
                      v-model="form.email"
                      :rules="form.rules.email"
                      dense
                      hide-details
                      label="E-mail"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="pt-0">
                  <v-col cols="12" md="12" class="">
                    <v-text-field
                      v-model="form.whatsapp"
                      :rules="form.rules.whatsapp"
                      dense
                      hide-details
                      label="WhatsApp"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row class="pt-0">
                  <v-col cols="12" md="12" class="">
                    <v-text-field
                      v-model="form.endereco"
                      :rules="form.rules.endereco"
                      dense
                      hide-details
                      label="Endereço"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          class="pr-4"
          large
          color="primary accent-4"
        >
          Atualizar Dados
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {

  components: {

  },

  props: [

  ],

  data: () => ({
    form: {
      valido: false,
      nome: '',
      sobrenome: '',
      cpf: '',
      email: '',
      whatsapp: '',
      endereco: '',
      rules: {
        nome: [ (v) => !!v || 'Informe o nome' ],
        sobrenome: [ (v) => !!v || 'Informe o sobrenome' ],
        cpf: [ (v) => !!v || 'Informe o cpf' ],
        email: [ (v) => !!v || 'Informe o email' ],
        whatsapp: [ (v) => !!v || 'Informe o whatsapp' ],
        endereco: [ (v) => !!v || 'Informe o endereco' ],
      }
    },
  }),

  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },

  watch: { },

  methods: {
    ...mapActions([
      //'carregarDependentesAction'
    ]),
    editarPerfil() {
      //
      //this.router.push({ quartos })
      //this.dialog = true
      //this.categoria = this.categorias[p.categoria-1].nome
      //this.$emit('detalhaProduto', p)
    }
  },

  created () {
    console.log('USER',this.user)
    //console.log("FORM", this.formBuscaHoteis)
    // console.log("PARAMS", this.$route.params)
    //this.carregarDependentesAction()
    //console.log('created Lista Dependentes')
  }

}
</script>