<template>
  <v-container class="pt-0 mt-0pl-0 ml-0 " fluid>

    <div class="text-left pl-0 ml-0 ">
      <v-btn icon class="pl-n3 ml-n5 text-left text-start" @click="voltar">
        <v-icon class="pl-0 ml-0 text-left text-start">mdi-arrow-left</v-icon>
      </v-btn>
    </div>

    <div>
      <hle-dependentes-lista
        v-if="dependentes"
        :dependentes="dependentes"
      />
    </div>

  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import HleDependentesLista from '../../components/Dependentes/DependentesLista'

export default {
  components: {
    HleDependentesLista
  },
  props: [
    // 'cidade',
    // 'checkin',
    // 'checkout',
    // 'adultos',
    // 'criancas'
  ],
  data: () => ({
    // loading: false,
    // form: {},
    // quarto: false
    dependentes: []
  }),
  computed: {
    ...mapState({
      userData: state => state.user.userData,
    }),
  },
  methods: {
    ...mapActions({
      carregarDependentesAction: 'user/carregarDependentesAction'
    }),
    voltar() {
      this.$router.go(-1)
    }
  },
  created () {
    // console.log('PARAMS',this.$router.params)
    // console.log('CIDADE',this.cidade)

    // this.form.city_code = this.cidade
    // this.form.checkin = this.checkin
    // this.form.checkout = this.checkout
    // this.form.adultos = this.adultos
    // this.form.criancas = this.criancas

    //this.carregarHoteisAction(this.form)
    console.log('userData',this.userData.sucesso.id)
    this.carregarDependentesAction(this.userData.sucesso.id)
      .then(dependentes => {
        this.dependentes = dependentes
        console.log('CREATED DEPENDENTES VIEW - Dependentes Retorno',dependentes)
      })
      .catch(e => {
        console.log('ERRO NA CAPTURA DE DEPENDENTES - DEPENDENTES VIEW >>> ',e)
      })


  }
}
</script>