<template>
  <v-app>
    <v-main>
      <v-container
        class="fill-height"
        fluid
        style="background-image: linear-gradient(to top, rgba(255, 255, 255, 0.6),rgba(152, 237, 0, 0.4));"
      >
        <v-row justify="center">
          <v-card class="elevation-1">
            <div >
              <v-img
                :lazy-src="require('@/assets/images/404.png')"
                :src="require('@/assets/images/404.png')"
                min-width="240"
              ></v-img>
            </div>
            <v-card-text class="py-6">
              <div class="text-h5 font-weight-bold">Página não encontrada.</div>
              <div>Ir para <router-link :to="goHome">Home</router-link></div>
            </v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    
  }),
  methods: {
    goHome() {
      this.$router.push({
        path: '/home'
      })
    }
  },
}
</script>