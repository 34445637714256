<template>
    <div class="assinatura-cards">
        <router-link
            to="/minha-assinatura"
        >
            <v-card
                class="mx-auto"
                width="190"
                outlined
            >
                <v-row
                align="center"
                justify="center"
                >
                <v-card-title>
                    <v-icon large>mdi-card-account-details-outline</v-icon>
                </v-card-title>
            </v-row>
            <v-card-text class="align-text-assinatura">MINHAS ASSINATURAS</v-card-text>
            </v-card>
        </router-link>

        <router-link
            class="space-card-assinatura"
            to="/dependentes"
        >
            <v-card
                class="mx-auto"
                width="190"
                outlined
            >
            <v-row
                align="center"
                justify="center"
                >
                <v-card-title>
                    <v-icon large>mdi-card-account-details-outline</v-icon>
                </v-card-title>
            </v-row>
            <v-card-text class="align-text-assinatura">DEPENDENTES</v-card-text>
            </v-card>
        </router-link>

        <router-link
            class="space-card-assinatura"
            to="/minha-fatura"
        >        
            <v-card
                class="mx-auto"
                width="190"
                outlined
            >
                <v-row
                align="center"
                justify="center"
                >
                <v-card-title>
                    <v-icon large>mdi-card-account-details-outline</v-icon>
                </v-card-title>
            </v-row>
            <v-card-text class="align-text-assinatura">FATURAS</v-card-text>
            </v-card>
        </router-link>
    </div>
</template>
<script>
export default {
    name: 'UserData'
}
</script>

<style scoped>
    .assinatura-cards {
        display: inline-flex;
        margin-top: 30px;;
    }
    .v-application a {
        text-decoration: none;
    }
    .space-card-assinatura {
        margin-left: 30px;
    }
    .align-text-assinatura {
        text-align: center;
        font-weight: 800;
    }
</style>