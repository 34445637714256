<template>
  <v-container class="pt-0 mt-0 ml-0 text-left">

    <div class="text-left d-inline text-h6 justify-center pl-0 ml-0">
      <v-btn text icon class="text-left text-start pl-0 ml-0" @click="voltar">
        <v-icon class="text-left text-start">mdi-arrow-left</v-icon>
      </v-btn>
        <div class="text-h6 d-inline ">
          <span>Reservar Quarto</span>
        </div>
    </div>

    <v-row class="pt-4">
      <v-col class="" cols="6">
        <div v-if="(hotel && usuarios)">

          <!-- <HospedeResponsavel
            :usuarios="usuarios"
          /> -->

          <!-- ACCESS TOKEN: {{ userData.token.DataAfterEncrypt }}
          <br><br>
          USER ID: {{ userData.sucesso.id }} -->

          <!-- <ReservaForm :hotelId="hotelId" :quartoId="quartoId" :quartoDescricao="quartoDescricao" /> -->
          <ReservaForm
            v-if="usuarios"
            :echoToken="$route.params.echoToken"
            :hotelCode="$route.params.hotelCode"
            :hotelSignature="$route.params.hotelSignature"
            :idQuarto="quarto"
            :preco="getPreco"
            :usuarios="usuarios"
          />
        </div>
      </v-col>
      <v-col class="" cols="6">
        <ProdutoCard
          :idQuarto="quarto"
        />
      </v-col>
    </v-row>


     <!-- <div v-else-if="hotel">
      <ReservaForm :hotelId="hotelId" :quartoId="quartoId" :quartoDescricao="quartoDescricao" />
    </div> -->
  </v-container>
</template>


<script>
import ProdutoCard from '../../components/Reserva/ProdutoCard'
//import HospedeResponsavel from '../../components/Reserva/HospedeResponsavel'
import ReservaForm from '../../components/Reserva/ReservaForm'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: {
    ProdutoCard,
    //HospedeResponsavel,
    ReservaForm
  },
  props: [
    // 'hotelId',
    // 'quartoId',
    // 'quartoDescricao'
    'quarto',
  ],

  methods:{
    ...mapActions({
      carregarUsuarioeDependentesAction: 'hotel/carregarUsuarioeDependentesAction'
    }),
    voltar() {
      this.$router.go(-1)
    }
  },

  data: () => ({
    usuarios: null
  }),

  computed: {
    ...mapState({
      // filtroBusca: state => state.filtroBusca,
      hotel: state => state.hotel.hotel,
      userData: state => state.user.userData,
      accessToken: state => state.user.accessToken,
      // hoteis: state => state.hoteis,
      //hotel: state => state.hoteis[this.hotelId]
    }),

    getPreco() {
      return this.hotel.RoomRates[0].Total.TPA_Extensions.PriceDescriptions[0].TotalPrice
    },

    checkin(){
      if(this.$route.params.checkin){
        moment.locale('pt-br')
        return moment(new Date(this.$route.params.checkin)).add(1,'day').format('D MMM')
      }
      return null
    },

    // checkout(){
    //   if(this.$route.params.checkin){
    //     moment.locale('pt-br')
    //     return moment(new Date(this.$route.params.checkin)).add(1,'day').format('D MMM')
    //   }
    //   return null
    // },
  },

  created () {
    // this.$router.push({name: 'HomeView'})
    // if(this.hotel == null) {
    //   //this.$router.push({path: '/home'})
    //   console.log('hotelzzz home >>>> ',this.hotel)
    //   this.$router.push({name: 'HomeView'})
    // }
    // else {
    //   console.log('hotel >>>> ',this.hotel)
    // }
    if(!this.hotel) {
      console.log('hotel é NULL')
      this.$router.push({
        path: '/'
      })
    }

    console.log('userData',this.userData)
    console.log('userData Sucesso',this.userData.sucesso)
    console.log('userData Sucesso ID',this.userData.sucesso.id)
    this.$store.dispatch('hotel/carregarUsuarioeDependentesAction', this.userData.sucesso.id).then(
      users => {
        console.log('users teste',users)
        this.usuarios = users
      },
      error => {
        //this.loading = false
        console.log('ERROR >>> ',error)
        // console.log('loginERror', this.loginError)
        // (error.response && error.response.data) ||
        //     error.message ||
        //     error.toString();
      }
    )
    // this.carregarUsuarioeDependentesAction(this.userData.sucesso.id)
    //   .then(function(ret) {
    //     this.usuarios = ret
    //   })
    //this.usuarios = this.carregarUsuarioeDependentesAction(this.userData.sucesso.id)
    console.log("Echo Token", this.$route.params.echoToken)
    console.log("Hotel Code", this.$route.params.hotelCode)
    console.log("Hotel Signature", this.$route.params.hotelSignature)
    console.log("Hotel", this.hotel)
    console.log("Quarto", this.quarto)
  }
}
</script>