<template>
  <v-container class="pt-0 mt-0pl-0 ml-0 " fluid>

    <!-- <div class="text-left pl-0 ml-0 ">
      <v-btn icon class="pl-n3 ml-n5 text-left text-start" @click="voltar">
        <v-icon class="pl-0 ml-0 text-left text-start">mdi-arrow-left</v-icon>
      </v-btn>
    </div> -->

    <div v-if="carregando">
      <v-row class="justify-center px-0">
        <v-col cols="6" class="px-0">
          <v-progress-linear
            color="primary accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
      Carregando hotéis...

      <v-row>
        <v-col>
          <v-carousel
            cycle
            :show-arrows="false"
          >
            <v-carousel-item
              v-for="(b,i) in banners"
              :key="i"
            >
              <v-img
                :src="getImg(b.imagem)"
              />
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>

    </div>
    <div v-else-if="hoteis">
      <hle-hoteis-lista :hoteis="hoteis" />
    </div>

  </v-container>
</template>

<script>
//import api from '@/api/api.js'
import { mapActions, mapState } from 'vuex'
import HleHoteisLista from '../../components/Hoteis/HoteisLista'

export default {
  name: 'HoteisView',
  components: {
    HleHoteisLista
  },
  props: [
    'cidade',
    'checkin',
    'checkout',
    'adultos',
    'criancas'
  ],
  data: () => ({
    loading: false,
    params: {},
    quarto: false,
    banners: [
      {
        imagem: '1.jpg',
      },
      {
        imagem: '2.jpg',
      },
      {
        imagem: '3.jpg',
      },
    ],
  }),
  computed: {
    ...mapState({
      carregando: state => state.hotel.carregandoHoteis,
      hoteis: state => state.hotel.hoteis,
      //userData: state => state.user.userData,
      accessToken: state => state.user.accessToken,
    })
  },
  methods: {
    ...mapActions({
      carregarHoteisAction: 'hotel/carregarHoteisAction'
    }),
    voltar() {
      this.$router.go(-1)
    },
    getImg(i) {
      return require('../../assets/images/banners/'+i)
    },
  },
  created () {
    this.params.city_code = this.cidade
    this.params.checkin = this.checkin
    this.params.checkout = this.checkout
    this.params.adultos = this.adultos
    this.params.criancas = this.criancas
    console.log('ACCESS TOKEN',this.accessToken)
    this.params.token = this.accessToken
    this.carregarHoteisAction(this.params)
  }
}
</script>