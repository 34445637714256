<template>
    <v-container>
        <h2><v-icon style="vertical-align: baseline;">mdi-account-circle</v-icon> MINHA ASSINATURA</h2>
        <assign></assign>
    </v-container>
</template>
<script>
import Assign from '../../components/UserData/Assign'
export default {
    name: 'AssignView',
    components: {
        Assign
    }
}
</script>