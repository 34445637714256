<template>
  <div style="margin-top: 25px;">
    <div class="text-center">
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <!-- <div style="display: flex;" v-if="this.plan"> -->
    <div style="display: flex;">
      <div class="plan-1" style="margin-right: 80px;">
        <v-card
          :style="this.plan == 1 ? '' : 'border: none;'"
          class="mx-auto"
          max-width="344"
          outlined
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">
                <strong class="plan-1-title">SEU PLANO: INDIVIDUAL</strong>
              </div>
              <div class="plan-1-text">
                <p>Escolha suas datas</p>
                <p>Benefícios do clube</p>
                <p>1,5 milhões hotéis</p>
                <p>Até 30% de desconto</p>
                <v-divider class="divide-space"></v-divider>
                <strong class="plan-1-title-sec">CICLO DE PAGAMENTO</strong>
                <p>Será cobrado o valor de R$95,00 a cada ciclo de cobrança.</p>
                <p>Esta assinatura será renovada automaticamente.</p>
                <p>Próximo pagamento: R$95,00 em 30 de Março de 2022.</p>
                <v-divider class="divide-space"></v-divider>
                  <span class="destaque">
                    Faça o upgrade
                  </span> para o Plano Familiar ou <span class="destaque">cancele a assinatura</span>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </div>
      <div class="plan-2">
        <v-card
          class="mx-auto"
          max-width="344"
          outlined
          :style="this.plan == 1 ? 'border: none;' : ''"
        >
          <v-list-item three-line style="margin-left: 70px;">
            <v-list-item-content>
              <div class="overline">
                ATUALIZE AGORA
              </div>
              <v-list-item-title class="headline">
                <v-chip>PLANO FAMILIAR</v-chip>
              </v-list-item-title>
              <v-row align="center">
                <v-col
                  class="display-3"
                  cols="10"
                >
                  <span style="font-size: 25px; display: inline-block; font-weight: 600; margin-right: -12px;">R$</span> 120
                  <span style="font-size: 12px; margin-top: -30px; display: block; text-align: center;">por mês</span>
                </v-col>
              </v-row>
              <p>Escolha suas datas</p><br />
              <p>Benefícios do clube</p><br />
              <p style="font-weight: bold;">1,5 milhões hotéis</p><br />
              <p style="font-weight: bold;">Até 30% de desconto</p><br />
              <p style="font-weight: bold;">Adicione 3 dependentes</p><br />
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn
              outlined
              text
              style="background: rgba(255, 27, 73, 1) !important; color: white; margin: 0 auto;"
            >
              ATUALIZAR
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'Assign',
    data: () => ({
        plan: '',
        loading: true,
    }),
    methods: {
        getPlan () {
            axios.get(`subscription/${65}`)
            .then(res => {
                const { plan_id } = res.data[0]
                this.plan = plan_id
                this.loading = false
            })
            .catch(errors => {
                console.log(errors)
                this.loading = false
            })
        }
    },
    created () {
        this.getPlan();
    }
}
</script>
<style scoped>
    .plan-1-title {
        font-size: 20px;
    }
    .plan-1-text {
        font-size: 13px;
    }
    .plan-1-title-sec {
        display: block;
        margin: 15px 0;
        font-weight: 500;
    }
    .destaque {
        color: rgba(255, 27, 73, 1) !important; 
        font-weight: bold;
    }
    .divide-space {
        margin: 30px 0;
    }
</style>