<template>
  <v-container class="pt-0 mt-0pl-0 ml-0 " fluid>

    <div class="text-left pl-0 ml-0 ">
      <v-btn icon class="pl-n3 ml-n5 text-left text-start" @click="voltar">
        <v-icon class="pl-0 ml-0 text-left text-start">mdi-arrow-left</v-icon>
      </v-btn>
    </div>

    <div>
      <v-row justify="space-around">
        <v-col
          md="4"
          class="px-0"
        >
          <h2 class="pt-0 pb-4 text-subtitle-1 text-left font-weight-bold" style="line-height: 1.2">
            <span class="barlow">Cadastrar Usuário</span>
          </h2>
          <div class="pb-4" >
            <v-card class="white grey--text text--darken-4 text-left">
              <v-form v-model="form.valido" ref="form">
                <v-container class="text-left pb-9">
                  <v-row>
                    <v-col class="pt-4 pb-2">
                      <div class="text-subtitle-1">
                        <v-icon color="primary" class="pr-1">mdi-account</v-icon>
                        <span class="barlow primary--text font-weight-bold text-uppercase">Dados Pessoais</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="pt-0">
                    <v-col cols="12" md="12" class="">
                      <v-text-field
                        v-model="form.nome"
                        :rules="form.rules.nome"
                        dense
                        hide-details
                        label="Nome"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pt-0">
                    <v-col cols="12" md="12" class="">
                      <v-text-field
                        v-model="form.sobrenome"
                        :rules="form.rules.sobrenome"
                        dense
                        hide-details
                        label="Nome"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pt-0">
                    <v-col cols="12" md="12" class="">
                      <v-text-field
                        v-model="form.cpf"
                        :rules="form.rules.cpf"
                        dense
                        hide-details
                        label="CPF"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pt-0">
                    <v-col cols="12" md="12" class="">
                      <v-text-field
                        v-model="form.email"
                        :rules="form.rules.email"
                        dense
                        hide-details
                        label="E-mail"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pt-0">
                    <v-col cols="12" md="12" class="">
                      <v-text-field
                        v-model="form.whatsapp"
                        :rules="form.rules.whatsapp"
                        dense
                        hide-details
                        label="WhatsApp"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row class="pt-0">
                    <v-col cols="12" md="12" class="">
                      <v-text-field
                        v-model="form.endereco"
                        :rules="form.rules.endereco"
                        dense
                        hide-details
                        label="Endereço"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            class="pr-4"
            large
            color="primary accent-4"
          >
            Atualizar Dados
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
//import HleDependentesLista from '../../components/Dependentes/DependentesLista'

export default {
  name: 'DependentesView',
  components: {
    //HleDependentesLista
  },
  props: [
    // 'cidade',
    // 'checkin',
    // 'checkout',
    // 'adultos',
    // 'criancas'
  ],
  data: () => ({
    // loading: false,
    form: {
      valido: false,
      nome: '',
      sobrenome: '',
      cpf: '',
      email: '',
      whatsapp: '',
      endereco: '',
      rules: {
        nome: [ (v) => !!v || 'Informe o nome' ],
        sobrenome: [ (v) => !!v || 'Informe o sobrenome' ],
        cpf: [ (v) => !!v || 'Informe o cpf' ],
        email: [ (v) => !!v || 'Informe o email' ],
        whatsapp: [ (v) => !!v || 'Informe o whatsapp' ],
        endereco: [ (v) => !!v || 'Informe o endereco' ],
      }
    },
    // quarto: false
  }),
  computed: {
    ...mapState({
      user: state => state.user,
      // carregando: state => state.carregandoHoteis,
      // hoteis: state => state.hoteis,
    })
  },
  methods: {
    ...mapActions([

    ]),
    voltar() {
      this.$router.go(-1)
    },
    editarPerfil() {}
  },
  created () {
    // console.log('PARAMS',this.$router.params)
    // console.log('CIDADE',this.cidade)

    // this.form.city_code = this.cidade
    // this.form.checkin = this.checkin
    // this.form.checkout = this.checkout
    // this.form.adultos = this.adultos
    // this.form.criancas = this.criancas

    //this.carregarHoteisAction(this.form)

  }
}
</script>