<template>
  <div class="pt-6">

    <v-form ref="form">
      <!-- <b>MODEL:</b> {{ model }}
      <br>
      <b>LOADING:</b> {{ isLoading }}
      <br>
      <b>SEARCH:</b> {{ search }}
      <br>
      <b>DESTINOS:</b> {{ destinos }}
      <br><br> -->

      <!-- <v-card class="white text-left"> -->

        <v-row class="px-4 text-left justify-start">

          <v-col md="2" cols="12" class="text-left align-left">
            <v-autocomplete
              v-model="form.destino"
              class="text-left"
              dense
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              menu-props="closeOnContentClick"
              color="primary"
              hide-no-data
              hide-selected
              item-text="Name"
              item-value="IataCode"
              label="Destino"
              prepend-inner-icon="mdi-map-marker"
              return-object
            ></v-autocomplete>
            <!-- {{form.destino}} -->
          </v-col>

          <v-col md="2" cols="12">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :active-picker.sync="activePicker"
              :close-on-content-click="false"

              offset-y
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- :value="computedDateFormattedMomentjs" -->
                <!-- :min="new Date().toISOString().substr(0, 10)" -->
                <!-- @click:clear="form.data1 = null" -->
                <!-- @blur="form.data1 = data1Formatada(dataFormatada)" -->
                <v-text-field
                  v-model="data1Formatada"
                  dense
                  label="Entrada"
                  :rules="form.rules.entradaRules"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  prepend-inner-icon="mdi-calendar"
                ></v-text-field>
              </template>
              <!-- <v-date-picker
                v-model="form.dataEntrada"
                @change="menu1 = false"
                locale="pt-br"
              ></v-date-picker> -->
              <v-date-picker
                v-model="form.checkin"
                :min="minDateEntrada()"
                locale="pt-BR"
                no-title
                reactive
                @change="save"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <v-col md="2" cols="12">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"

              offset-y
              max-width="290"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- :value="computedDateFormattedMomentjs" -->
                <!-- :min="new Date().toISOString().substr(0, 10)" -->
                <!-- @click:clear="form.data2 = null" -->
                <v-text-field
                  v-model="data2Formatada"
                  :active-picker.sync="activePicker"
                  dense
                  label="Saída"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <!-- <v-date-picker
                v-model="form.dataEntrada"
                @change="menu1 = false"
                @change="save"
                locale="pt-br"
              ></v-date-picker> -->
              <v-date-picker
                v-model="form.checkout"
                :min="minDateSaida()"
                locale="pt-BR"
                :show-current="dataCorrente"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>

          <!-- <v-col
            cols="12"
            md="2"
          >
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :model="form.dataSaida"
                  value=""
                  label="Saída"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  prepend-icon="mdi-calendar"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="pt-br"
                v-model="form.dataSaida"
                @change="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col> -->
          <v-col md="2" cols="12">
            <v-select
              class="text-left"
              v-model="form.adultos"
              dense
              :items="adultos"
              :rules="form.rules.adultosRules"
              color="primary"
              label="Adultos"
              required
              prepend-inner-icon="mdi-account"
            ></v-select>
          </v-col>
          <v-col md="2" cols="12">
            <v-select
              v-model="form.criancas"
              dense
              :items="criancas"
              :rules="form.rules.criancasRules"
              color="primary"
              label="Crianças"
              required
              @click.stop="dialog = true"
              prepend-inner-icon="mdi-human-child"
            ></v-select>
          </v-col>
          <v-col md="1" cols="12" class="">
            <v-btn
              block
              large
              class="primary"
              @click="submit"
            >BUSCAR</v-btn>
          </v-col>

        </v-row>
      <!-- </v-card> -->
    </v-form>
  </div>
</template>

<style >
  .v-list-item__content {
    text-align: left !important;
  }
</style>

<script>
import api from '@/api/api.js'
//import qs from 'qs'
import moment from 'moment'
// import { format, parseISO } from 'date-fns'
import { mapActions, mapState } from 'vuex'

  export default {
   name: 'FiltroHoteis',
   data: () => ({
    isLoading: false,
    search: null,
    nameLimit: 60,
    destinos: [],

    // data1: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    // data2: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
    menu1: false,
    menu2: false,
    activePicker: null,
    dataCorrente: null,

    adultos:[
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8'
    ],
    criancas:[
      '0',
      '1',
      '2',
      '3',
      '4',
      '5'
    ],

    form: {
      destino: null,
      cidade: null,
      count: 0,
      checkin: '',
      checkout: '',
      // data2: new Date().toISOString().substr(0, 10),
      adultos: null,
      criancas: null,
      rules: {
        entradaRules: [ (v) => !!v || 'Informe a data de entrada' ],
        adultosRules: [ (v) => !!v || 'Informe o número de adultos' ],
        criancasRules:[v => !!v || 'Informe o número de crianças']
      }
    },


  }),
  computed: {
    ...mapState({
      //destinos: state => state.cidades,
      carregandoQuartos: state => state.hotel.carregandoQuartos
    }),
    data1Formatada () {
      if(this.form.checkin)
        return moment(new Date(this.form.checkin)).add(1,'day').format('DD/MM/YYYY')
      return null
    },
    data2Formatada () {
      if(this.form.checkout)
        return moment(new Date(this.form.checkout)).add(1,'day').format('DD/MM/YYYY')
      return null
    },
    items () {
      return this.destinos.map(entry => {
        const Name = entry.length > this.nameLimit
          ? entry.Name.slice(0, this.nameLimit) + '...'
          : entry.Name + ', ' + entry.StateCode + ', ' + entry.CountryName
        return Object.assign({}, entry, { Name })
      })
    },
  },
  watch: {
    search (val) {

      // Items have already been loaded
      if (this.items.length > 3) return

      // Items have already been requested
      if (this.isLoading) return


      // Lazily load input items
      // api.getCidades(
      //   (res) => {
      //     console.log('foi', res)
      //     //const { count, entries } = res
      //     const { entries } = res
      //     console.log('res',res)
      //     console.log('length', res.entries.length)
      //     console.log('entries', res.entries)
      //     this.count = entries.length
      //     this.destinos = entries
      //   }
      // )
      if (val.length >= 3) {
        this.isLoading = true
        this.buscarCidadesAction(
          val
        ).then((res) => {
          this.destinos = res
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
      }

      // api.getCidades(
      //   (res) => {
      //     console.log('foi', res)
      //     //const { count, entries } = res
      //     //const { entries } = res
      //     // console.log('res',res)
      //     // console.log('length', res.entries.length)
      //     // console.log('entries', res.entries)
      //     this.count = res.length
      //     this.destinos = res
      //   }
      // )

      // fetch('https://api.publicapis.org/entries')
      //   .then(res => res.json())
      //   .then(res => {
      //     const { count, entries } = res
      //     this.count = count
      //     this.entries = entries
      //     console.log('RESULTADO >>> ',res)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      //   .finally(() => (this.isLoading = false))
    },

  },
  methods: {
    ...mapActions({
      buscarCidadesAction: 'buscarCidadesAction',
      buscaHoteis: 'hotel/buscaHoteis',
      setFormBuscaHoteis: 'hotel/setFormBuscaHoteis'
    }),
    botao() {
      api.avail()
    },
    save (data) {
      if(this.minDateSaida() < this.minDateEntrada()){
        //alert('pobrema')
        console.log('problema no datepicker - minDateSaida < minDateEntrada')
      }
      this.$refs.menu1.save(data)
    },
    minDateEntrada () {
      //console.log('MIN DATE',new Date())
      //return new Date().toString()

      //let dt = moment().locale('pt-br').format('L')
      //let dt = moment().format('YYYY/MM/DD')
      //console.log('FORMAT',moment().format('YYYY/MM/DD'))
      //return '2022-05-06'
      //console.log('FORMAT 2',moment().format())
      return moment().format('YYYY-MM-DD')
    },
    minDateSaida () {
      let dts = moment(this.form.checkin).add(1,'days').format('YYYY-MM-DD')
      this.dataCorrente = dts
      return dts
    },
    // formatDate (date) {
    //     if (!date) return null

    //     const [year, month, day] = date.split('-')
    //     return `${day}/${month}/${year}`
    //   },
    //   parseDate (date) {
    //     if (!date) return null

    //     const [day,month,  year] = date.split('/')
    //     return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    //   },
    url (val) {
      return val != null ? true : false
    },
    submit() {
      //console.log('SUBMIT')
      //console.log('MODEL',this.model)
      //console.log('SUBMIT',this.form)

      if( this.$refs.form.validate()){


        this.setFormBuscaHoteis(this.form)
        //this.buscaHoteis(this.form)

        this.$router.push( {
          path: '/busca/hoteis/'
            + 'cidade/' + this.form.destino.IataCode
            + '/checkin/' + this.form.checkin
            + '/checkout/' + this.form.checkout
            + '/adultos/' + this.form.adultos
            + '/criancas/' + this.form.criancas
        } )

        //this.carregandoQuartos = true
        //console.log(this.carregaQuartos)

        // let params = qs.stringify({
        //   "start_date": "2021-04-28",
        //   "end_date": "2021-06-28",
        //   "city": "BSB",
        //   "guests": [
        //     {
        //       "age": 25,
        //       "count": 1
        //     }
        //   ]
        // })

      }

    }
  },
  created() {
    //this.minDate()
    //this.destinos = cidades
    //this.buscarCidadesAction()

    // MOCK
    //this.form.destino = this.destinos[0]
    // this.form.data1 = '2022-06-26'
    // this.form.data2 = '2022-06-28'
    this.form.adultos = '2'
    this.form.criancas = '0'
  }
}
</script>
