<template>
  <v-container fluid class="mx-0 px-0 ma-0 mr-0">

    <v-row v-if="hotel && idQuarto" no-gutters>
      <v-col
        cols="12"
        class="mx-0 px-0 pr-2"
      >
        <v-card class="pa-4">
          <v-card-text class="pt-2 px-0">
            <div class="text-h5 font-weight-bold" style="line-height: 1.3">
              <span class="grey--text text--darken-4">{{ hotel.BasicPropertyInfo.HotelName }}</span>
            </div>
            <div class="pb-2 font-weight-bold" style="line-height: 1.3">
              <v-icon color="primary" small v-for="i in Math.floor(hotel.TPA_Extensions.Stars)" :key="i" >mdi-star</v-icon>
            </div>
            <div class="text-overline font-weight-regular">
              <span class="">{{ hotel.BasicPropertyInfo.Address.CityName }}/{{ hotel.BasicPropertyInfo.Address.StateProv.StateCode }}</span>
            </div>
          </v-card-text>
          <!-- {{h}} -->
          <v-img
            max-height="180"
            :src="hotel.TPA_Extensions.HotelImage"
            class=""
          />

          <v-card-text class="pt-4">
            <p class="text-h6 font-weight-bold" style="line-height: 1.3">
              <span class="grey--text text--darken-4">{{ hotel.RoomTypes[idQuarto].RoomDescription.Text }}</span>
            </p>
            <div class="text-caption font-weight-regular">
              <span>Valor da Diária</span>
            </div>
            <div class="text-h5 pt-0 font-weight-medium">
              <span class="grey--text text--darken-4">R$ {{ parseFloat(hotel.RoomRates[idQuarto].Total.TPA_Extensions.PriceDescriptions[0].UnitPrice.toFixed(2)) | real }}</span>
            </div>
            <div class="text-caption pt-1" style="line-height: 1.2">
              <span class="font-weight-medium">{{ diarias }} diárias por R$ {{ parseFloat(hotel.RoomRates[idQuarto].Total.TPA_Extensions.PriceDescriptions[0].TotalPrice.toFixed(2)) | real }}</span> (+ taxas)<br>
              <span>Até 12x no cartão</span><br>
              <span>Checkin: {{ checkin }} / {{ diarias }} diárias</span>
            </div>
          </v-card-text>

          <v-card-text class="pt-0 pb-0">
            <div>
              <span>Máximo de hóspedes: {{ hotel.RoomTypes[idQuarto].Occupancy.MaxOccupancy }}</span>
            </div>
          </v-card-text>

          <v-card-text class="pt-0">
            <div>
              <span>Não-Reembolsável: {{ hotel.RoomTypes[idQuarto].TPA_Extensions.NonRefundable == true ? 'Sim' : 'Não' }}</span>
            </div>
          </v-card-text>


          <v-card-text class="pl-3 py-0">
            <v-dialog
              v-model="dialog"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="mx-0 px-2 text-caption"
                  color="primary accent-4"
                  @click="verPoliticaDeCancelamento()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small class="pr-1">mdi-sticker-alert-outline</v-icon>
                  <span class="">Política de Cancelamento</span>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Política de Privacidade
                </v-card-title>

                <v-card-text class="pt-4 text-left">
                  <!-- <div v-if="carregandoPolitica" class="text-subtitle-1">
                    <span>Carregando...</span>
                  </div>
                  <div v-else> -->
                  <div>
                    <!-- Reembolsável: {{ quarto.TPA_Extensions.NonRefundable == true ? 'Não' : 'Sim' }} -->
                    <!-- Reembolsável: {{ quarto.TPA_Extensions }} -->
                  </div>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col>
        Quarto {{ quarto }}
      </v-col>
    </v-row> -->

  </v-container>
</template>

<script>
  import { mask } from 'vue-the-mask'
  import { mapGetters, mapActions, mapState } from 'vuex'
  import moment from 'moment'

  export default {
    directives: {mask},
    props: [
      'idQuarto'
    ],
    data: () => ({
      dialog: false,
      carregandoPolitica: false
    }),

    computed: {
      ...mapGetters('hotel', {
        getHotelById: 'getHotelById'
      }),
      ...mapState({
        // filtro: state => state.filtroBusca
        hotel: state => state.hotel.hotel,
      }),
      checkin(){
        if(this.$route.params.checkin){
          moment.locale('pt-br')
          return moment(new Date(this.$route.params.checkin)).add(1,'day').format('D MMM')
        }
        return null
      },
      // hotel(){
      //   return this.getHotelById(this.hotelId)
      // },
      // quarto(){
      //   return this.hotel.RoomTypes.filter(r => r.RoomID === this.quartoId )
      // }
      diarias(){
        return moment(new Date(this.$route.params.checkout)).diff(new Date(this.$route.params.checkin), "days")
      }
    },

    methods: {
      ...mapActions({
        res: 'hotel/res',
        carregarPolitica: 'hotel/carregarPolitica'
      }),
      verPoliticaDeCancelamento() {
        this.carregandoPolitica = true
        this.carregarPolitica(this.$route.params.hotelCode)
      },
    },
    created () {


    }
  }
</script>