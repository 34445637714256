<template>
  <div>
    <v-container
      fluid
      class="grey darken-4 pb-16 mx-0"
    >

      <v-row  class="justify-center text-center">
        <div class=" white--text font-weight-regular"
          :class="{
          'text-center text-h2 pt-3': $vuetify.breakpoint.mdAndUp,
          'text-h3 text-center pt-6 pb-4 px-4': $vuetify.breakpoint.smAndDown,
          }"
        >
          <span class="roboto">ATUALIZE SEU PLANO</span>
        </div>
      </v-row>
      <v-row
        class="justify-center"
        :class="{
          'pt-12': $vuetify.breakpoint.mdAndUp,
          '': $vuetify.breakpoint.smAndDown,
        }"
      >
        <div class="pt-8 text-h5 white--text font-weight-light justify-center">
            <span class="barlow "
              :class="{
                'mx-auto pb-3': $vuetify.breakpoint.mdAndUp,
                '': $vuetify.breakpoint.smAndDown,
              }"
            >
              Confira as opções de Plano Holé e escolha o mais adequado ao seu perfil
            </span>
        </div>
      </v-row>


      <v-row class="justify-center text-center pb-12 px-6 white--text">

        USERDATA: {{userData}}
      <!-- Card individual -->

        <v-col
          v-for="(p, i) in planos"
          :key="i"
          cols="12" md="4"
          class=" "
          :class="{
            'pt-14': $vuetify.breakpoint.mdAndUp,
            'pt-6 pb-10': $vuetify.breakpoint.smAndDown,
          }"
        >
          <!-- <div v-if="p.id === userData.id"
            class="white--text"
          >
            <v-chip class="transparent white--text">
              <v-icon size="medium" class="primary--text pr-1">mdi-arrow-down</v-icon>Seu Plano
              <v-icon size="medium" class="primary--text pl-1">mdi-arrow-down</v-icon></v-chip>
          </div>
          <div v-else>
            <v-chip class="transparent"></v-chip>
          </div>
          <v-card-text class="pt-0 pb-2 font-weight-light">
            <div class="text-h4">
              <span class="barlow primary--text font-weight-light" v-html="p.tipo"></span>
            </div>
          </v-card-text>
          <v-card class="fill-height">
            <v-card
              color="white black--text"
              flat fill-height
              class="pt-6 text-left fill-height d-flex align-content-stretch flex-wrap justify-center"
            >
              <v-card-title v-if="p.preco" color="transparent" class="pt-0 pb-0 justify-center">
                <div class="text-h5 font-weight-regular "
                  :class="{
                    'text-center': $vuetify.breakpoint.mdAndUp,
                    'pr-0': $vuetify.breakpoint.smAndDown,
                  }"
                >
                  <span class="barlow">{{ p.cifrao}} </span><span class="text-h2 font-weight-medium">
                    <span class="barlow">{{ p.preco }}</span>
                  </span>
                </div>
              </v-card-title>
              <v-card-text v-if="p.periodo" color="transparent " class=" pb-0">
                <div class="text-subtitle-1 mt-n1 font-weight-light "
                  :class="{
                    'pt-0 text-center ': $vuetify.breakpoint.mdAndUp,
                    'text-center': $vuetify.breakpoint.smAndDown,
                  }"
                ><span class="barlow " v-html="p.periodo"></span></div>
              </v-card-text>
              <v-card-text
                color="transparent"
                class="justify-center pb-0"
                :class="{
                  'px-6': $vuetify.breakpoint.mdAndUp,
                  'text-center': $vuetify.breakpoint.smAndDown,
                }"
              >
                <div class="pt-0 text-h6 font-weight-light"><span class="barlow" v-html="p.descricao"></span></div>
              </v-card-text>
              <v-card-actions class="pt-0 pb-6">
                <v-btn
                  v-if="p.id != '3'"
                  large
                  block
                  color="primary"
                  class="mb-0 px-10 py-6 text-h5"
                  to="/novo-usuario"
                >
                  <span class="roboto font-weight-regular text-uppercase" v-html="p.cta"></span>
                </v-btn>
              </v-card-actions>
            </v-card>


          </v-card> -->
        </v-col>
      </v-row>
      <!-- <v-row class="pb-16 justify-center text-center">
        <div class="text-h3 white--text text-center">
          <span class="barlow">TESTE GRÁTIS POR 3 MESES</span>
        </div>
      </v-row>
      <v-row  class="justify-center text-center pb-10">
        <div class="text-caption white--text text-center">
          <span class="font-weight-regular">*Esses benefícios serão disponibilizados após o período de gratuidade!</span>
        </div>
      </v-row> -->
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'MeuPlano',
  components: {

  },
  props: [
  ],
  data: () => ({
    // hotel: {}
    // planos: [
    //   {
    //     ativo: false,
    //     nome: 'Plano Individual',
    //     valor: '94,80',
    //     beneficios: [
    //       '1,5 milhão de Hotéis e Resorts',
    //       'Até 30% de desconto sobre as tarifas públicas',
    //       'Assistência Viagem',
    //       'Título de Capitalização com sorteios mensais de R$ 5 mil pela Loteria Federal'
    //     ]
    //   },
    //   {
    //     ativo: false,
    //     nome: 'Plano Família',
    //     valor: '119,90',
    //     beneficios: [
    //       '1,5 milhão de Hotéis e Resorts',
    //       'Até 3 dependentes',
    //       'Até 30% de desconto sobre as tarifas públicas',
    //       'Assistência Viagem',
    //       'Título de Capitalização com sorteios mensais de R$ 5 mil pela Loteria Federal'
    //     ]
    //   },
    //   {
    //     ativo: true,
    //     nome: 'Plano VIP',
    //     valor: 'FREE',
    //     beneficios: [
    //       '1,5 milhão de Hotéis e Resorts',
    //       'Até 3 dependentes',
    //       '1 ano de gratuidade',
    //     ]
    //   }
    // ],
    planos: [{
        id: '3',
        tipo: 'FREE',
        valor: 'FREE',
        descricao :'<ul>\n\
          <li>1,5 milhão de Hotéis e Resorts</li>\n\
          <li>Até 30% de desconto sobre as tarifas públicas</li>\n\
          <li>1 usuário</li>\n\
        </ul>',
        cta: 'Assinar'
      },
      {
        id: '1',
        tipo: 'INDIVIDUAL',
        valor: '94,80',
        descricao: '<ul>\n\
          <li>1,5 milhão de Hotéis e Resorts</li>\n\
          <li>Até 30% de desconto sobre as tarifas públicas</li>\n\
          <li>1 usuário</li>\n\
        </ul>',
        cta: 'Fazer Upgrade'
      },
      {
        id: '2',
        tipo: 'FAMILIAR',
        valor: '119,90',
        descricao :'<ul>\n\
          <li>1,5 milhão de Hotéis e Resorts</li>\n\
          <li>Até 30% de desconto sobre as tarifas públicas</li>\n\
          <li>5 usuários</li>\n\
        </ul>',
        cta: 'Fazer Upgrade'
      },
    ]
  }),

  computed: {
    ...mapState({
      dependentes: state => state.dependentes,
      userData: state => state.userData
    }),
  },

  watch: { },

  methods: {
    ...mapActions([
      //'carregarDependentesAction'
    ]),
  },
  created () {
    //console.log("FORM", this.formBuscaHoteis)
    // console.log("PARAMS", this.$route.params)
    //this.carregarDependentesAction()
    console.log('created MeuPlano')
    console.log('userData',this.userData)
  }

}
</script>