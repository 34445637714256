<template>
  <div>
    <v-row v-if="dependentes" justify="space-around">
      <v-col
        md="12"
        class="px-0"
      >
        <h2 class="pt-0 pb-4 text-subtitle-1 text-left font-weight-bold" style="line-height: 1.2">
          <!-- <v-icon style="vertical-align: baseline;">mdi-office-building-marker</v-icon>  -->
          <span class="barlow">Você tem {{ dependentes.length }} usuários dependentes cadastrados</span>
        </h2>
        <div class="pb-4">
          <v-row>
            <v-col
              v-for="(d,i) in dependentes" :key="i"
              md="4"
            >
              <v-card
                class="white fill-height grey--text text--darken-4 text-left"
              >
                <v-card-text class="pt-2">
                  <v-list-item class="grow pl-0">
                    <v-list-item-avatar color="grey lighten-2">
                      <v-img
                        class="elevation-6"
                        alt=""
                        :src="d.imagem"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="">
                        <span class="font-weight-medium text-uppercase">{{d.nome}} {{d.sobrenome}}</span>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <div class="text-overline font-weight-regular" style="line-height: 2">
                    <v-icon small class="pr-1">mdi-badge-account-horizontal-outline</v-icon>
                    <span class="">Cadastrado em {{ d.cadastro }}</span>
                  </div>
                  <div class="text-overline font-weight-regular" style="line-height: 2">
                    <v-icon small class="pr-1">mdi-email</v-icon>
                    <span class="">{{ d.email }}</span>
                  </div>
                  <div class="text-overline font-weight-regular" style="line-height: 2">
                    <v-icon small class="pr-1">mdi-cellphone</v-icon>
                    <span class="">{{ d.telefone }}</span>
                  </div>
                  <!-- <div class="text-overline font-weight-regular" style="line-height: 2">
                    <v-icon small class="pr-1">mdi-star</v-icon>
                    <span class="">Nascimento: {{ d.nascimento }}</span>
                  </div> -->
                  <div class="text-caption font-weight-regular" style="line-height: 2">
                    <v-icon small class="pr-1">mdi-badge-account-horizontal-outline</v-icon>
                    <span class="">Cadastrado em {{ d.cadastro }}</span>
                  </div>
                </v-card-text>
                <v-card-actions class="pb-6">
                  <v-row
                    justify="start"
                  >
                    <v-btn
                      class="pr-4 pl-5 my-0 py-0"
                      text
                      color="primary accent-4"
                      :to="linkReserva"
                    >
                      Reservar Hotel
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                    <v-btn
                      class="pr-4 pl-5 my-0 py-0"
                      text
                      color="primary accent-4"
                      @click="excluirDependente(d.id)"
                    >
                      Excluir Dependente
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>

            <!-- ADD NEW -->
            <v-col md="4" class="flex text-center align-center">
              <v-card
                dark
                height="240"
                class="fill-height pt-16 pb-4 primary white--text align-center justify-center"
                to="novo-usuario"
              >
                <div class="">
                  <span class="font-weight-medium text-h6">Cadastrar Usuário</span>
                </div>
                <div class="text-overline font-weight-regular" style="line-height: 2">
                  <v-icon size="54" class="pr-1">mdi-plus-thick</v-icon>
                </div>
              </v-card>
            </v-col>

          </v-row>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {

  },
  props: [
    'dependentes'
  ],
  data: () => ({
    // hotel: {}
    linkReserva: 'home'
  }),

  computed: {
    ...mapState({
      //dependentes: state => state.dependentes,
    }),
  },

  watch: { },

  methods: {
    ...mapActions({
      //carregarDependentesAction: 'user/carregarDependentesAction'
    }),
    excluirDependente(d) {
      console.log('EXCLUIR DEPENDENTE ',d)
    },
    editarDependente() {
      //
      //this.router.push({ quartos })
      //this.dialog = true
      //this.categoria = this.categorias[p.categoria-1].nome
      //this.$emit('detalhaProduto', p)
    }
  },
  created () {
    //console.log("FORM", this.formBuscaHoteis)
    // console.log("PARAMS", this.$route.params)
    // console.log('userData',this.userData.sucesso.id)
    // this.carregarDependentesAction(this.userData.sucesso.id)
    console.log('created Lista Dependentes')
  }

}
</script>