<template>
  <div v-if="drawer">
  <!-- <div v-if="drawer"> -->
    <v-navigation-drawer
      v-model="drawerModel"
      absolute
      temporary
      right
    >

      <v-form v-model="formFiltro.valido" ref="form">
        <v-container class="text-left">
          <v-row>
            <v-col class="pt-4 pb-2">
              <div class="text-subtitle-1">
                <v-icon color="primary" class="pr-1">mdi-filter</v-icon>
                <span class="barlow primary--text font-weight-bold text-uppercase">Filtrar Busca</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="pt-0 pb-4">
            <v-col cols="12" md="12" class="">
              <div class="text-body-2">
                <!-- {{formBuscaHoteis}}
                <br> --- <br> -->
                <!-- {{form.destino}} <br><br> -->
              </div>
              <v-autocomplete
                v-model="form.destino"
                class="text-left"
                dense
                :items="items"
                :loading="isLoading"
                :search-input.sync="search"
                menu-props="closeOnContentClick"
                color="primary"
                hide-no-data
                hide-selected
                item-text="Name"
                item-value="IataCode"
                label="Destino"
                prepend-inner-icon="mdi-map-marker"
                return-object
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="12" class="">
              <v-text-field
                outlined
                dense
                v-model="formFiltro.nomeHotel"
                hide-details
                label="Bairro"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :active-picker.sync="activePicker"
                :close-on-content-click="false"
                offset-y
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data1Formatada"
                    dense
                    label="Entrada"
                    :rules="form.rules.entradaRules"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    prepend-inner-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.checkin"
                  :min="minDateEntrada()"
                  locale="pt-BR"
                  no-title
                  reactive
                  @change="save"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="12">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                offset-y
                max-width="290"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="data2Formatada"
                    :active-picker.sync="activePicker"
                    dense
                    label="Saída"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="form.checkout"
                  :min="minDateSaida()"
                  locale="pt-BR"
                  :show-current="dataCorrente"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col md="12" cols="12">
              <v-select
                class="text-left"
                v-model="form.adultos"
                dense
                :items="adultos"
                :rules="form.rules.adultosRules"
                color="primary"
                label="Adultos"
                required
                prepend-inner-icon="mdi-account"
              ></v-select>
            </v-col>
            <v-col md="12" cols="12">
              <v-select
                v-model="form.criancas"
                dense
                :items="criancas"
                :rules="form.rules.criancasRules"
                color="primary"
                label="Crianças"
                required
                @click.stop="dialog = true"
                prepend-inner-icon="mdi-human-child"
              ></v-select>
            </v-col>
            <v-col cols="12" md="12" class="py-0">
              <v-range-slider
                v-model="formFiltro.range"
                label="Preço"
                :max="max"
                :min="min"
                hide-details
                class="align-center"
              ></v-range-slider>
            </v-col>
            <v-col cols="6" md="6" class="d-flex">
              <v-text-field
                outlined
                dense
                label="Mínimo"
                :value="formFiltro.range[0]"
                class="mt-0 pt-0 px-0 mx-0"
                hide-details
                type="number"
                prefix="R$"
                style="width: 90px"
                @change="$set(formFiltro.range, 0, $event)"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="6" class="d-flex">
              <v-text-field
                outlined
                dense
                label="Máximo"
                :value="formFiltro.range[1]"
                class="mt-0 pt-0 px-0 mx-0"
                hide-details
                type="number"
                prefix="R$"
                style="width: 90px"
                @change="$set(formFiltro.range, 1, $event)"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="pt-0">
            <v-col class="pt-0 text-left">
              <div>
                <v-btn
                  large
                  class="primary"
                  @click="filtrar"
                >
                  <span>Buscar</span>
                  <v-icon class="pl-1">mdi-arrow-right</v-icon>
                </v-btn>
                <!-- <v-btn @click="mudar">Mudar</v-btn> -->
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'ListaHoteisFiltroDrawer',
  components: {

  },
  props: {
    drawer: {
      type : Boolean,
      required : true
    },
    // drawerControleInterno: {
    //   type : Boolean,
    //   required : true
    // },
  },
  data: () => ({
    menu1: false,
    menu2: false,
    activePicker: null,
    dataCorrente: null,
    adultos:[
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8'
    ],
    criancas:[
      '0',
      '1',
      '2',
      '3',
      '4',
      '5'
    ],
    isLoading: false,
    search: null,
    destinos: [],
    formFiltro: {
      nomeHotel: '',
      valido: false,
      range: [50, 1400],
    },
    min: 50,
    max: 1400,
    hotel: {},
    form: {
      destino: null,
      cidade: null,
      count: 0,
      checkin: '',
      checkout: '',
      // data2: new Date().toISOString().substr(0, 10),
      adultos: null,
      criancas: null,
      rules: {
        entradaRules: [ (v) => !!v || 'Informe a data de entrada' ],
        adultosRules: [ (v) => !!v || 'Informe o número de adultos' ],
        criancasRules:[v => !!v || 'Informe o número de crianças']
      }
    },

  }),

  computed: {
    ...mapState({
      formBuscaHoteis: state => state.formBuscaHoteis
    }),
    data1Formatada () {
      if(this.form.checkin)
        return moment(new Date(this.form.checkin)).add(1,'day').format('DD/MM/YYYY')
      return null
    },
    data2Formatada () {
      if(this.form.checkout)
        return moment(new Date(this.form.checkout)).add(1,'day').format('DD/MM/YYYY')
      return null
    },
    items () {
      return this.destinos.map(entry => {
        const Name = entry.length > this.nameLimit
          ? entry.Name.slice(0, this.nameLimit) + '...'
          : entry.Name + ', ' + entry.StateCode + ', ' + entry.CountryName
        return Object.assign({}, entry, { Name })
      })
    },
    drawerModel: {
      get: function(){
        return this.drawer;
      },
      set: function(newValue){
        this.$emit('update:drawer', newValue)
      }
    },
  },

  watch: {
    search (val) {
      if (this.items.length > 3) return // Items have already been loaded

      if (this.isLoading) return // Items have already been requested

      if (val.length >= 3) {
        this.isLoading = true
        this.buscarCidadesAction(
          val
        ).then((res) => {
          this.destinos = res
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
      }
    },
  },

  methods: {
    ...mapActions([
      'buscarCidadesAction'
    ]),
    minDateEntrada () {
      return moment().format('YYYY-MM-DD')
    },
    minDateSaida () {
      let dts = moment(this.form.checkin).add(1,'days').format('YYYY-MM-DD')
      this.dataCorrente = dts
      return dts
    },
    save (data) {
      if(this.minDateSaida() < this.minDateEntrada()){
        //alert('pobrema')
        console.log('problema no datepicker - minDateSaida < minDateEntrada')
      }
      this.$refs.menu1.save(data)
    },
    mudar() {
      //this.form.destino = 0
      this.form = this.formBuscaHoteis
    },
    filtrar() {
      console.log('entrou filtrar() ')
      console.log(this.form.destino)
      //prod.filter(p => p.categoria == cat && p.promocao != 1)
      // this.filtrarHoteisAction(this.formFiltro)
      // this.drawer = !this.drawer
    },
  },

  created () {
    this.form = this.formBuscaHoteis

    // this.form.destino = {
    //   'Name': 'Salvador',
    //   'IataCode': 'SSA'
    // }
    //this.form.destino = this.form.destino.Name + ', ' + this.form.destino.StateCode + ', ' + this.form.destino.CountryName
  }

}
</script>

<style >
  /* .v-list-item__content {
    text-align: left !important;
  } */
</style>