<template>
  <div class="">
    <!-- HOTEIS: {{hoteis}}
    <br><br>
    ------ -->
  <!-- <div
    v-if="hoteis != null"
    class=""
  > -->
    <!-- <v-row no-gutters>
      <v-col class="pb-6">
        <span class="caption">1 hotel encontrado</span>
        <div>FILTROBUSCA: <br>{{ filtroBusca }}</div>
        <div>HOTÉIS: <br>{{ hoteis }}</div>
      </v-col>
    </v-row> -->
    <v-row v-if="hotel" class="px-0">
      <v-col
        md="12"
        class="px-0"
      >
        <!-- <h2 class="pt-0 pb-4 text-h6 text-left font-weight-bold" style="line-height: 1.2">
          <span class="barlow">{{ hoteis.length }} Hotéis Encontrados</span>
        </h2> -->

        <div
          class="pb-2 px-0 mx-0 text-left"
        >
          <!-- {{h.RoomRates[0].Total.TPA_Extensions.PriceDescriptions[0]}} -->
          <!-- <v-card
            v-if="getPreco(h) != null"
            class="white grey--text text--darken-4 text-left"
          > -->
          <v-card
            flat
            class="px-0 mx-0 white grey--text text--darken-4 text-left"
          >
            <v-card-text class="pt-2 px-0">
              <div class="text-h5 font-weight-bold" style="line-height: 1.3">
                <span class="grey--text text--darken-4">{{ hotel.BasicPropertyInfo.HotelName }}</span>
              </div>
              <div class="pb-2 font-weight-bold" style="line-height: 1.3">
                <v-icon color="primary" small v-for="i in Math.floor(hotel.TPA_Extensions.Stars)" :key="i" >mdi-star</v-icon>
              </div>
              <div class="text-overline font-weight-regular">
                <span class="">{{ hotel.BasicPropertyInfo.Address.CityName }}/{{ hotel.BasicPropertyInfo.Address.StateProv.StateCode }}</span>
              </div>
            </v-card-text>
            <!-- {{h}} -->
            <v-img
              max-height="180"
              :src="hotel.TPA_Extensions.HotelImage"
              class=""
            />

            <!-- <v-img
              class=""
              height="200"
              max-width="auto"
              :src="h.TPA_Extensions.HotelImage"
            />
            <v-card-title class="pt-3 pb-0 text-start text-subtitle-2 text-uppercase">
              <span class="barlow font-weight-light">
                {{ h.BasicPropertyInfo.Address.CityName }},
                {{ h.BasicPropertyInfo.Address.StateProv.StateCode }},
                {{ h.BasicPropertyInfo.Address.Country }}
              </span>
            </v-card-title>
            <v-card-text class="pr-0 pb-0 text-h6 pt-1 grey--text text--darken-4">
              <span class="barlow">{{ h.BasicPropertyInfo.HotelName }}</span>
            </v-card-text>
            <span class="pt-0 pl-4 pb-4">
              <span
                v-for="n in parseInt(h.TPA_Extensions.Stars)"
                :key="n"
                class=""
              >
                <v-icon small class="grey--text text--darken-3">mdi-star</v-icon>
              </span>
            </span>

            <v-card-text class="pt-2 pb-0 text-caption">
              <span>{{q.apartir}}</span>
            </v-card-text>
            <span
              class="pt-0 mx-4 pb-4 text-h4 grey--text text--darken-3 font-weight-medium"
              :class="{
                '' : q.url
              }"
            >
              <span class="text-caption">R$</span>{{q.valor}}
            </span>
            <v-spacer></v-spacer>-->

            <v-card-actions class="pt-3 px-0">
              <v-btn
                large
                block
                text
                class=""
                color= "primary white--text"
                @click="$vuetify.goTo('#disponibilidade')"
              >
                <v-icon>mdi-chevron-down</v-icon>
                VER QUARTOS
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <v-row id="disponibilidade" class="px-0 pb-0">
      <v-col class="text-left px-0">
        <div class="text-h6">
          <span class="barlow">Disponibilidade</span>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="hotel" class="pt-0">
        <!-- <h2 class="pt-0 pb-4 text-h6 text-left font-weight-bold" style="line-height: 1.2">
          <span class="barlow">{{ hoteis.length }} Hotéis Encontrados</span>
        </h2> -->

      <v-col
        cols="12"
        md="4"
        v-for="(q,i) in hotel.RoomTypes"
        :key="i"
        class="pb-6 pr-6 pl-0 pt-0"
      >
        <!-- {{h.RoomRates[0].Total.TPA_Extensions.PriceDescriptions[0]}} -->
        <!-- <v-card
          v-if="getPreco(h) != null"
          class="white grey--text text--darken-4 text-left"
        > -->
        <v-card
          class="pb-3 white grey--text text--darken-4 text-left fill-height"
        >
          <!-- {{q}} -->
          <!-- <v-img
            max-height="180"
            :src="hotel.TPA_Extensions.HotelImage"
            class=""
          /> -->
          <v-card-text class="pt-4">
            <!-- <div class="text-overline font-weight-regular">
              <span class="">{{ q. }}</span>
            </div> -->
            <p class="text-h6 font-weight-bold" style="line-height: 1.3">
              <span class="grey--text text--darken-4">{{ q.RoomDescription.Text }}</span>
            </p>
            <div class="text-caption font-weight-regular">
              <span>Diária</span>
            </div>
            <div class="text-h5 pt-0 font-weight-medium">
              <span class="grey--text text--darken-4">R$ {{ parseFloat(hotel.RoomRates[i].Total.TPA_Extensions.PriceDescriptions[0].UnitPrice.toFixed(2)) | real }}</span>
            </div>
            <div class="text-caption pt-1" style="line-height: 1.2">
              <span class="font-weight-medium">{{ diarias }} diárias por R$ {{ parseFloat(hotel.RoomRates[i].Total.TPA_Extensions.PriceDescriptions[0].TotalPrice.toFixed(2)) | real }}</span>
              <span> (+ taxas)</span><br>
              <span>Em até 12x no cartão</span><br>
              <!-- <span>Checkin: {{ checkin }} / {{ diarias }} diárias</span> -->
            </div>
          </v-card-text>

          <v-card-text class="pt-0 pb-0">
            <div>
              <span>Máximo de hóspedes: {{ q.Occupancy.MaxOccupancy }}</span>
            </div>
            <!-- <div>
              <span>NonRefundable: {{ q.TPA_Extensions.NonRefundable }}</span>
            </div> -->
            <div>
              <span>Reembolsável: {{ q.TPA_Extensions.NonRefundable == true ? 'Não' : 'Sim' }}</span>
            </div>
          </v-card-text>


          <v-card-text class="pt-3 pl-3 py-0">
            <v-dialog
              v-model="dialog"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="mx-0 px-2 text-caption"
                  color="primary accent-4"
                  @click="verPoliticaDeCancelamento()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon small class="pr-1">mdi-sticker-alert-outline</v-icon>
                  <span class="">Política de Cancelamento</span>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Política de Privacidade
                </v-card-title>

                <v-card-text class="pt-4 text-left">
                  <!-- <div v-if="carregandoPolitica" class="text-subtitle-1">
                    <span>Carregando...</span>
                  </div>
                  <div v-else> -->
                  <div>
                    Reembolsável: {{ q.TPA_Extensions.NonRefundable == true ? 'Não' : 'Sim' }}
                  </div>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                  >
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>

          <v-card-actions class="pb-3 pl-3">
            <v-btn
              class="mr-4"
              color="primary accent-4"
              @click="reservar(
                i
              ); reveal=true"
            >
              Reservar
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

export default {
  name: 'ListaQuartos',
  components: {

  },
  props: [
  ],
  data: () => ({
    dialog: false,
    carregandoPolitica: false
  }),

  computed: {
    // ...mapGetters({
    //   hoteis: 'getHoteis'
    // }),
    ...mapState({
      hotel: state => state.hotel.hotel
    }),
    checkin(){
      if(this.$route.params.checkin){
        moment.locale('pt-br')
        return moment(new Date(this.$route.params.checkin)).add(1,'day').format('D MMM')
      }
      return null
    },
    diarias(){
      return moment(new Date(this.$route.params.checkout)).diff(new Date(this.$route.params.checkin), "days")
    }
  },

  watch: {

  },

  methods: {
    ...mapActions({
      carregarPolitica: 'hotel/carregarPolitica'
    }),
    verPoliticaDeCancelamento() {
      this.carregandoPolitica = true
      this.carregarPolitica(this.$route.params.hotelCode)
    },
    getPreco(h) {
      // if(tpa_extensions[0].CurrencyCode == "BRL")
      //   return tpa_extensions[0]
      // else if(tpa_extensions[1].CurrencyCode == "BRL")
      //   return tpa_extensions[1]
      return h.RoomRates[0].Total.TPA_Extensions.PriceDescriptions[1]
    },
    reservar(room) {
      //this.setHotelAction(hotelCode)
      this.$router.push(
        {
          path: '/reserva'
            + '/' + this.$route.params.cidade
            + '/' + this.$route.params.hotelCode
            + '/' + this.$route.params.checkin
            + '/' + this.$route.params.checkout
            + '/' + 2
            + '/' + 0
            + '/' + this.$route.params.echoToken
            + '/' + this.$route.params.hotelSignature
            + '/quarto/' + room
        }
      )
    }
  },

  created () {
    console.log("QuartosLista Params Created", this.$route.params)
    //console.log("QUARTOSLISTA HOTEL", this.hotel)

  }

}
</script>
