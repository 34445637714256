var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"grey darken-4 pb-16 mx-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"justify-center text-center"},[_c('div',{staticClass:" white--text font-weight-regular",class:{
        'text-center text-h2 pt-3': _vm.$vuetify.breakpoint.mdAndUp,
        'text-h3 text-center pt-6 pb-4 px-4': _vm.$vuetify.breakpoint.smAndDown,
        }},[_c('span',{staticClass:"roboto"},[_vm._v("ATUALIZE SEU PLANO")])])]),_c('v-row',{staticClass:"justify-center",class:{
        'pt-12': _vm.$vuetify.breakpoint.mdAndUp,
        '': _vm.$vuetify.breakpoint.smAndDown,
      }},[_c('div',{staticClass:"pt-8 text-h5 white--text font-weight-light justify-center"},[_c('span',{staticClass:"barlow ",class:{
              'mx-auto pb-3': _vm.$vuetify.breakpoint.mdAndUp,
              '': _vm.$vuetify.breakpoint.smAndDown,
            }},[_vm._v(" Confira as opções de Plano Holé e escolha o mais adequado ao seu perfil ")])])]),_c('v-row',{staticClass:"justify-center text-center pb-12 px-6 white--text"},[_vm._v(" USERDATA: "+_vm._s(_vm.userData)+" "),_vm._l((_vm.planos),function(p,i){return _c('v-col',{key:i,staticClass:" ",class:{
          'pt-14': _vm.$vuetify.breakpoint.mdAndUp,
          'pt-6 pb-10': _vm.$vuetify.breakpoint.smAndDown,
        },attrs:{"cols":"12","md":"4"}})})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }