<template>
  <div>

    <HleHoteisListaFiltroDrawer :drawer.sync="drawer" />

    <v-form v-model="form.valido">
      <v-row class="pt-2">
        <v-col class="px-0 text-left shrink align-center pr-0">
          <div class="d-flex fill-height text-left pl-0 ml-0">
            <v-btn icon class="align-self-center pl-n3 ml-n5" @click="voltar">
              <v-icon class="pl-0 ml-0">mdi-arrow-left</v-icon>
            </v-btn>
          </div>
        </v-col>
        <v-col class="pl-1">
          <div class="">
            <v-sheet
              rounded="xl"
              class="d-flex"
              width="100%"
              color="grey lighten-4 text-left"
            >
              <div class="pa-4 grow">
                <div class="text-subtitle-1" style="line-height: 1">
                  <span class="barlow font-weight-medium">
                    {{ cidade.Name }}, {{ cidade.StateName }}, {{ cidade.CountryName }}
                  </span>
                </div>
                <div class="pt-1 text-caption" style="line-height: 1">
                  <span class="roboto font-weight-regular ">
                    {{ dataCheckinFormatada }} - {{ dataCheckoutFormatada }}
                  </span>
                </div>
              </div>
              <div class="d-inline align-self-center pr-4">
                <v-btn
                  fab
                  small
                  color="primary"
                  @click.stop="drawer = !drawer"
                  class=""
                >
                  <v-icon>mdi-tune-variant</v-icon>
                </v-btn>
              </div>
            </v-sheet>
          </div>
        </v-col>
      </v-row>
    </v-form>

    <!-- <v-row class="pt-2 pb-0 text-left">
      <v-col class="pb-0 pl-0">
        <v-sheet
          class="justify-center align-center"
          height="56"
          color="grey lighten-3"
          style="border-radius: 25px 25px 25px 25px"
        >
          <v-form class="justify-center align-center" light>
            <v-autocomplete
              v-model="form.destino"
              class="text-left"
              filled dense rounded outlined
              :items="items"
              :loading="isLoading"
              :search-input.sync="search"
              menu-props="closeOnContentClick"
              color="primary"
              hide-no-data
              hide-selected
              item-text="Name"
              item-value="IataCode"
              label="Destino"
              prepend-inner-icon="mdi-map-marker"
              return-object
            ></v-autocomplete>
            <v-text-field
              filled
              dense
              rounded
              outlined
              class=""
              label="Entrada"
              color="primary"
            >
              dsf
            </v-text-field>
            <v-text-field
              filled
              dense
              rounded
              outlined
              class=""
              label="Saída"
              color="primary"
            >
              dsf
            </v-text-field>
          </v-form>
        </v-sheet>
      </v-col>
    </v-row> -->

    <v-row class="pt-0" v-if="hoteis">
      <v-col
        md="12"
        class="pt-6 px-0 text-left"
      >
        <!-- <div class="pt-2 pb-4">
          <v-btn
            outlined
            color="primary"
            @click.stop="drawer = !drawer"
            class="pl-3"
          >
            <v-icon>mdi-filter</v-icon>
            Filtrar Busca
          </v-btn>
        </div> -->

        <h2 class="pt-0 pb-4 text-h6 text-left font-weight-bold" style="line-height: 1.2">
          <!-- <v-icon style="vertical-align: baseline;">mdi-office-building-marker</v-icon>  -->
          <span class="barlow">{{ hoteis.length }} Hotéis Encontrados</span>
        </h2>

        <!-- {{ hoteis[0].TPA_Extensions }} -->


        <div
          v-for="(h,i) in hoteis"
          :key="i"
          class="pb-4"
        >
          <!-- {{ h.TPA_Extensions.HotelImage }} -->
          <!-- {{ h.BasicPropertyInfo.HotelCityCode }} -->
          <!-- {{h.RoomRates[0].Total.TPA_Extensions.PriceDescriptions[0]}} -->

          <!-- <v-card
            v-if="getPreco(h) != null"
            class="white grey--text text--darken-4 text-left"
          > -->
          <v-card
            class="white grey--text text--darken-4 text-left"
            v-if="h && h.TPA_Extensions"
          >
            <v-img
              max-height="180"
              :src="h.TPA_Extensions.HotelImage"
              class=""
            />
            <v-card-text class="pt-2">
              <div class="text-overline font-weight-regular">
                <span class="">{{ h.BasicPropertyInfo.Address.CityName }}/{{ h.BasicPropertyInfo.Address.StateProv.StateCode }}</span>
              </div>
              <div class="pb-1 font-weight-bold" style="line-height: 1.3">
                <v-icon color="primary" small v-for="i in Math.floor(h.TPA_Extensions.Stars)" :key="i" >mdi-star</v-icon>
              </div>
              <p class="text-subtitle-1 font-weight-bold" style="line-height: 1.3">
                <span class="grey--text text--darken-4">{{ h.BasicPropertyInfo.HotelName }}</span>
              </p>
              <div class="text-caption font-weight-regular">
                <span>Diárias a partir de</span>
              </div>
              <div class="text-h5 pt-0 font-weight-medium">
                <span class="grey--text text--darken-4">R$ {{ parseFloat(h.RoomRates[0].Total.TPA_Extensions.PriceDescriptions[0].UnitPrice.toFixed(2)) | real }}</span>
              </div>
              <div class="text-caption pt-1" style="line-height: 1.2">
                <span class="font-weight-medium">{{ diarias }} diárias por R$ {{ h.RoomRates[0].Total.TPA_Extensions.PriceDescriptions[0].TotalPrice | real }}</span>
                <span> (+ taxas)</span><br>
                <span>Em até 12x no cartão</span><br>
                <!-- <span>Checkin: {{ formBuscaHoteis.checkin }} / {{ diarias }} diárias</span> -->
              </div>
            </v-card-text>
            <v-card-actions class="pb-3">
              <!-- {{h.BasicPropertyInfo}} -->
              <!-- <v-icon class="ml-2 mr-1" color="primary">
                mdi-heart
              </v-icon>
              <span class="subheading mr-4">256</span> -->
              <v-row
                align="center"
                justify="end"
              >
              <v-col>
                <!-- HOTEL SIGNATURE: {{ h.BasicPropertyInfo.Signature }} -->
                <!-- {{ h.BasicPropertyInfo.HotelCode }} -->
                <v-btn
                  class="pr-4"
                  text
                  color="primary accent-4"
                  @click="buscaQuartos(
                    h.BasicPropertyInfo
                  ); reveal=true"
                >
                  Ver Quartos
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
              </v-row>
            </v-card-actions>
            <!-- <v-img
              class=""
              height="200"
              max-width="auto"
              :src="h.TPA_Extensions.HotelImage"
            />
            <v-card-title class="pt-3 pb-0 text-start text-subtitle-2 text-uppercase">
              <span class="barlow font-weight-light">
                {{ h.BasicPropertyInfo.Address.CityName }},
                {{ h.BasicPropertyInfo.Address.StateProv.StateCode }},
                {{ h.BasicPropertyInfo.Address.Country }}
              </span>
            </v-card-title>
            <v-card-text class="pr-0 pb-0 text-h6 pt-1 grey--text text--darken-4">
              <span class="barlow">{{ h.BasicPropertyInfo.HotelName }}</span>
            </v-card-text>
            <span class="pt-0 pl-4 pb-4">
              <span
                v-for="n in parseInt(h.TPA_Extensions.Stars)"
                :key="n"
                class=""
              >
                <v-icon small class="grey--text text--darken-3">mdi-star</v-icon>
              </span>
            </span>

            <v-card-text class="pt-2 pb-0 text-caption">
              <span>{{q.apartir}}</span>
            </v-card-text>
            <span
              class="pt-0 mx-4 pb-4 text-h4 grey--text text--darken-3 font-weight-medium"
              :class="{
                '' : q.url
              }"
            >
              <span class="text-caption">R$</span>{{q.valor}}
            </span>
            <v-spacer></v-spacer>

            <v-card-actions class="pt-3 px-3">
              <v-btn
                large
                block
                tile
                class="mb-3"
                color= "primary white--text"
                link
                :to="{
                  name: 'QuartosView',
                  params: {hotelId: i}
                }"
              >
                VER QUARTOS
              </v-btn>
            </v-card-actions> -->


            <!-- {{h}} -->
          </v-card>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import HleHoteisListaFiltroDrawer from '@/components/Hoteis/HoteisListaFiltroDrawer'

export default {
  name: 'ListaHoteis',
  components: {
    HleHoteisListaFiltroDrawer
  },
  props: [
  ],
  data: () => ({
    drawer: false,
    //drawerControleInterno: false,
    isLoading: false,
    search: null,
    destinos: [],
    formFiltro: {
      nomeHotel: '',
      valido: false,
      range: [50, 1400],
    },
    min: 50,
    max: 1400,
    hotel: {},
    form: {
      destino: null,
      cidade: null,
      count: 0,
      checkin: '',
      checkout: '',
      // data2: new Date().toISOString().substr(0, 10),
      adultos: null,
      criancas: null,
      rules: {
        entradaRules: [ (v) => !!v || 'Informe a data de entrada' ],
        adultosRules: [ (v) => !!v || 'Informe o número de adultos' ],
        criancasRules:[v => !!v || 'Informe o número de crianças']
      }
    },

  }),

  computed: {
    // ...mapGetters({
    //   hoteis: 'getHoteis'
    // }),
    ...mapState({
      echoToken: state => state.user.echoToken,
      hoteis: state => state.hotel.hoteis,
      cidade: state => state.cidade,
      cidades: state => state.cidades,
      formBuscaHoteis: state => state.hotel.formBuscaHoteis
    }),

    dataCheckinFormatada () {
      if(this.form.checkin){
        moment.locale('pt-br')
        return moment(new Date(this.form.checkin)).add(1,'day').format('D MMM')
      }
      return null
    },

    dataCheckoutFormatada () {
      if(this.form.checkout)
        return moment(new Date(this.form.checkout)).add(1,'day').format('D MMM')
      return null
    },

    items () {
      return this.destinos.map(entry => {
        const Name = entry.length > this.nameLimit
          ? entry.Name.slice(0, this.nameLimit) + '...'
          : entry.Name + ', ' + entry.StateCode + ', ' + entry.CountryName
        return Object.assign({}, entry, { Name })
      })
    },

    diarias() {
      return moment(new Date(this.$route.params.checkout)).diff(new Date(this.$route.params.checkin), "days")
      //return Math.abs(this.formBuscaHoteis.checkout - this.formBuscaHoteis.checkin)
      //return Math.abs(this.$route.params.checkout - this.$route.params.checkin)
    }

  },

  watch: {

    search (val) {
      if (this.items.length > 3) return // Items have already been loaded

      if (this.isLoading) return // Items have already been requested

      if (val.length >= 3) {
        this.isLoading = true
        this.buscarCidadesAction(
          val
        ).then((res) => {
          this.destinos = res
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => (this.isLoading = false))
      }
    },
  },

  methods: {
    ...mapActions({
      buscarCidadesAction: 'buscarCidadesAction',
      setHotelAction: 'hotel/setHotelAction',
      filtrarHoteisAction: 'hotel/filtrarHoteisAction',
    }),
    filtrar() {
      //prod.filter(p => p.categoria == cat && p.promocao != 1)
      this.filtrarHoteisAction(this.formFiltro)
      this.drawer = !this.drawer
    },
    getPreco(h) {
      // if(tpa_extensions[0].CurrencyCode == "BRL")
      //   return tpa_extensions[0]
      // else if(tpa_extensions[1].CurrencyCode == "BRL")
      //   return tpa_extensions[1]
      return h.RoomRates[0].Total.TPA_Extensions.PriceDescriptions[1]
    },
    detalhaHotel(h) {
      this.hotel = h
      //this.router.push({ quartos })
      //this.dialog = true
      //this.categoria = this.categorias[p.categoria-1].nome
      //this.$emit('detalhaProduto', p)
    },
    //buscaQuartos(cityCode, hotelCode, hotelSignature) {
    buscaQuartos(h) {

      console.log('h',h)

      // h.BasicPropertyInfo.HotelCityCode,
      // h.BasicPropertyInfo.HotelCode,
      // h.BasicPropertyInfo.Signature

      //this.setHotelAction(h.BasicPropertyInfo.HotelCode)
      this.setHotelAction(h.HotelCode)
      //console.log('novo hotel code #sqn > ', hotelCode)
      //console.log('hoteis',this.hoteis)

      this.$router.push(
        {
          path: '/hoteis'
            + '/' + h.HotelCityCode
            + '/' + h.HotelCode
            + '/' + this.$route.params.checkin
            + '/' + this.$route.params.checkout
            + '/' + 2
            + '/' + 0
            + '/' + this.echoToken
            + '/' + h.Signature
        }
      )
    },
    voltar() {
      this.$router.go(-1)
    }
  },

  created () {
    //console.log("FORM", this.formBuscaHoteis)
    console.log("PARAMS", this.$route.params)
    console.log('hoteis HoteisLista created',this.hoteis)

    if(!this.cidades){
      console.log('cidades', this.cidades)
    }
    //this.form.destino = this.cidade

    console.log('cidade', this.cidade)
    //console.log('destino', this.form.destino) // NULL

    //this.form.checkin = moment(new Date(this.$route.params.checkin)).add(1,'day').format('DD/MM/YYYY')
    this.form.checkin = this.$route.params.checkin
    this.form.checkout = this.$route.params.checkout

    // this.form.destino = {
    //   'Name': 'Salvador',
    //   'IataCode': 'SSA'
    // }

    // TODO - O QUE FAZER QUANDO formBuscaHoteis É NULL?
    // console.log('formBuscaHoteis', this.formBuscaHoteis)
    // this.form.checkin = this.formBuscaHoteis.checkin
    // this.form.checkout = this.formBuscaHoteis.checkout

  }

}
</script>

<style >
  .v-list-item__content {
    text-align: left !important;
  }
</style>