<template>
  <v-container class="pt-0 mt-0pl-0 ml-0 " fluid>

    <div class="text-left pl-0 ml-0 ">
      <v-btn icon class="pl-n3 ml-n5 text-left text-start" @click="voltar">
        <v-icon class="pl-0 ml-0 text-left text-start">mdi-arrow-left</v-icon>
      </v-btn>
    </div>

    <div>
      <!-- <hle-dependentes-lista :hoteis="hoteis" /> -->
      <perfil />
    </div>

  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Perfil from '../../components/Perfil/Perfil'

export default {
  name: 'PerfilView',
  components: {
    Perfil
  },
  props: [
    // 'cidade',
    // 'checkin',
    // 'checkout',
    // 'adultos',
    // 'criancas'
  ],
  data: () => ({
    // loading: false,
    // form: {},
    // quarto: false
  }),
  computed: {
    ...mapState({
      // carregando: state => state.carregandoHoteis,
      // hoteis: state => state.hoteis,
    })
  },
  methods: {
    ...mapActions([

    ]),
    voltar() {
      this.$router.go(-1)
    }
  },
  created () {
    // console.log('PARAMS',this.$router.params)
    // console.log('CIDADE',this.cidade)

    // this.form.city_code = this.cidade
    // this.form.checkin = this.checkin
    // this.form.checkout = this.checkout
    // this.form.adultos = this.adultos
    // this.form.criancas = this.criancas

    //this.carregarHoteisAction(this.form)

  }
}
</script>