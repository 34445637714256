<template>
    <v-container>
        <h2><v-icon style="vertical-align: baseline;">mdi-card-account-details-outline</v-icon> ASSINATURA</h2>
        <user-data></user-data>
    </v-container>
</template>
<script>
import UserData from '../../components/UserData/UserData'
export default {
    name: 'UserDataView',
    components: {
        UserData
    }
}
</script>