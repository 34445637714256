<template>
  <v-container class="px-0 pt-0">
    <v-row>
      <v-col class="text-left">
        <h2 class="text-h4" style="line-height: 1.2">
          <span class="barlow font-weight-bold">As melhores tarifas de hotéis estão aqui!</span>
        </h2>
      </v-col>
    </v-row>
    <v-row class="pb-9">
      <v-col>
        <v-img
          :src="getImg(hotel)"
        />
      </v-col>
    </v-row>
    <div class="px-4 text-left">
      <h6 class="pt-2 pb-6 text-subtitle-1" style="line-height: 1.3">
        <span class="barlow font-weight-light">Configure os dados abaixo para consultar o valor da sua estadia com desconto</span>
      </h6>
    </div>
    <v-card>
      <!-- <v-card-title>adsf</v-card-title> -->
      <hle-form-hoteis/>
    </v-card>
  </v-container>
</template>

<script>
// @ is an alias to /src
//import api from '@/api/api.js'
import { mapActions } from 'vuex'
import HleFormHoteis from '@/components/Hoteis/FormHoteis'

export default {
  name: 'HomeView',
  components: {
    HleFormHoteis
  },
  data: () => ({
    hotel: 'teju-acu.jpg',
    form: {}
  }),
  methods: {
    ...mapActions([
      //'carregarHoteisAction'
    ]),
    getImg(i) {
      return require('@/assets/images/hoteis/'+i)
    },
  },
  created () {
    // console.log('PARAMS',this.$router.params)
    // console.log('CIDADE',this.cidade)

    // this.form.city_code = 'BSB'
    // this.form.checkin = '2022-06-28'
    // this.form.checkout = '2022-06-30'
    // this.form.adultos = '1'
    // this.form.criancas = '0'


    //this.carregarHoteisAction(this.form)
    //this.carregarHoteisAction()

  }
}
</script>
