<template>
    <v-container>
         <h2><v-icon style="vertical-align: baseline;">mdi-account-cash</v-icon> MINHAS FATURAS</h2>
        <invoice></invoice>
    </v-container>
</template>
<script>
import Invoice from '../../components/UserData/Invoice'
export default {
    name: 'InvoiceView',
    components: {
        Invoice
    }
}
</script>