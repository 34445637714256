<template>
  <v-container fluid class="mx-0 px-0 ma-0 mr-0">

    <!-- FORMULÁRIO PARA RESERVA  -->
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-row class="pt-0 pb-0">
        <v-col col="12">
          <v-card
            v-if="usuarios"
            class="pa-4"
          >
            <span class="primary--text font-weight-medium">RESPONSÁVEL PELA RESERVA</span>
            <div>
              <v-select
                v-model="form.usuarioReserva"
                :items="usuarios"
                label="Selecione o Usuário"
                item-text="usuarios"
                item-value="id"
              >
                <template v-slot:selection="{item}">
                  {{ item.nome }} {{ item.sobrenome }} {{ item.titular == '1' ? '(Titular)' : '' }}
                </template>
                <template v-slot:item="{item}">
                  {{ item.nome }} {{ item.sobrenome }} {{ item.titular == '1' ? '(Titular)' : '' }}
                  - {{item.id}}
                </template>
              </v-select>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="pt-1">
        <v-col col="12">
          <v-card class="pa-4">
            <span class="primary--text font-weight-medium">DADOS DO CARTÃO DE CRÉDITO</span>

            <!-- <v-text-field
              v-model="form.nome"
              :rules="nameRules"
              label="Primeiro Nome"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.sobrenome"
              :rules="nameRules"
              label="Último Nome"
              required
            ></v-text-field>
            <v-text-field
              v-model="form.nascimento"
              label="Data de Nascimento"
              color="primary"
            ></v-text-field>
            <v-text-field
              v-model="form.cpf"
              :rules="cpfRules"
              v-mask="cpfMask"
              label="CPF"
              color="primary"
            ></v-text-field> -->
            <!-- <v-text-field
              v-model="email"
              :rules="emailRules"
              label="E-mail"
              required
            ></v-text-field> -->
            <v-select
              label="Parcelas"
              v-model="form.selectedParcelas"
              :items="parcelas"
              item-text="valorParcela"
              item-value="numeroParcelas"
              required
            >
              <!-- <template v-slot:selection="{item}">
                {{ item.nome }} {{ item.sobrenome }} {{ item.titular == '1' ? '(Titular)' : '' }}
              </template>
              <template v-slot:item="{item}">
                {{ item.nome }} {{ item.sobrenome }} {{ item.titular == '1' ? '(Titular)' : '' }}
              </template> -->
            </v-select>

            <v-text-field
              v-model="form.nomeCartao"
              :rules="nomeCartaoRules"
              :counter="30"
              label="Nome do proprietário"
              required
            ></v-text-field>

            <v-text-field
              v-model="form.numeroCartao"
              :rules="numeroCartaoRules"
              v-mask="numeroCartaoMask"
              label="Número"
              required
            ></v-text-field>

            <v-text-field
              v-model="form.dataExpiracao"
              :rules="dataExpiracaoRules"
              label="Validade"
              required
              v-mask="dataExpiracaoMask"

            ></v-text-field>

            <v-text-field
              v-model="form.cvv"
              :rules="cvvRules"
              label="CVV"
              required
              :counter="3"
            ></v-text-field>

          </v-card>
        </v-col>
      </v-row>
      <v-row class="pt-1 text-left justify-left">
        <v-col>
          <!-- <span class="pr-6">
              <v-checkbox
                  v-model="checkbox"
                  :rules="[v => !!v || 'You must agree to continue!']"
                  label="Do you agree?"
                  required
              ></v-checkbox>
          </span> -->
          <v-btn
            large
            color="primary"
            class="mr-4 text-large"
            @click="reservar"
          >
            Reservar
          </v-btn>
        </v-col>
      </v-row>
      <br>
      <!-- USUARIOS: {{usuarios}}<br> -->
      USUARIO: {{form.usuarioReserva}}<br>
      CHECKIN: {{checkin}}<br>
      CHECKOUT: {{checkout}}<br>
    </v-form>
  </v-container>
</template>

<script>
  import { mask } from 'vue-the-mask'
  import { mapGetters, mapActions, mapState } from 'vuex'

  export default {
    directives: {mask},
    props: [
      // 'hotelId',
      // 'quartoId',
      // ''
      'usuarios',
      'echoToken',
      'hotelCode',
      'hotelSignature',
      'idQuarto',
      'preco',
      //'checkin',
      //'checkout',
    ],
    data: () => ({
      nrParcelas: 12,
      form: {
        usuarioReserva: null,
        selectedParcelas: 1,
        nomeCartao: '',
        numeroCartao: '',
        dataExpiracao: '',
        cvv: '',
        checkin: null,
        checkout: null
      },
      valid: true,
     // masked: false,
      numeroCartaoMask:'#### #### #### ####',
      dataExpiracaoMask:'##/####',
      mask: '(##) #####-####',
      cvv:'',
      dataExpiracao:'',
      numeroCartao:'',
      nomeCartao:'',
      nomeCartaoRules: [
        v => !!v || 'Informe o nome do titular do cartão',
        v => v.length <= 30 || 'Nome com até 25 caracteres',
      ],
      numeroCartaoRules: [
        v => !!v || 'Informe um número válido',
      ],
      cvvRules: [
        v => !!v || 'Informe o CVV válido',
        v => v.length <= 3 || 'Digite os 3 números de segurança',
      ],
      dataExpiracaoRules: [
        v => !!v || 'Informe a data de validade do cartão',
      ],
      nameRules: [
        v => !!v || 'Informe o nome completo',
        v => (v && v.length <= 30) || 'Nome maior que 25 caracteres',
      ],
    }),

    computed: {
      ...mapGetters({
        getHotelById: 'hotel/getHotelById'
      }),
      ...mapState({
        // filtro: state => state.filtroBusca
        hotel: state => state.hotel.hotel,
      }),
      // selected() {
      //   if(this.usuarios){
      //     return this.usuarios[0].id
      //   }
      // },

      checkin(){
        return this.$route.params.checkin ?? null
      },

      checkout(){
        return this.$route.params.checkout ?? null
      },

      parcelas(){
        let ret = []
        for(let i = 0; i < this.nrParcelas; i++) {
          let nrParcelas = i+1
          ret[i] = {
            valorParcela: nrParcelas + 'x de R$ ' + this.$options.filters.real(parseFloat((this.preco / nrParcelas).toFixed(2))),
            numeroParcelas: nrParcelas
          }
        }
        return ret
      },
      // hotel(){
      //   return this.getHotelById(this.hotelId)
      // },
      // quarto(){
      //   return this.hotel.RoomTypes.filter(r => r.RoomID === this.quartoId )
      // }
    },

    methods: {
      ...mapActions({
        res: 'hotel/res'
      }),

      // selectedUsuario(){
      //   console.log('selected',this.selected)
      //   // if(this.usuarios){
      //   //   return this.usuarios[0].id
      //   // }
      //   return null
      // },
      reservar () {
        //this.$refs.form.validate()
        //this.res(this.form, this.echoToken, )
        //this.form.usuario = this.idUsuarioSelecionado

        // this.form.hotelCode = this.hotelCode
        // this.form.echoToken = this.echoToken
        // this.form.hotelSignature = this.hotelSignature
        // this.form.tpaProvider = this.hotel.RoomTypes[this.idQuarto].TPA_Extensions.Provider
        // this.form.tpaSignature = this.hotel.RoomTypes[this.idQuarto].TPA_Extensions.Signature
        //console.log('ReservaForm', this.form)
        //this.res(this.resParams)
        this.form.checkin = this.checkin
        this.form.checkout = this.checkout

        this.res(this.form)
          .then(res => {
            this.$router.push({
              path: '/processamento-reserva',
              params: {resultado: res }
            })
          })
          .catch(e => {
            console.log('HOUVE UM ERRO NA HORA DA RESERVA - RESERVA FORM >>> ', e)
          })
      },
    },
    async created () {
      //console.log('hotel res hotel',this.hotel)
      console.log('idQuarto', this.idQuarto)
      console.log('hotelCode', this.hotelCode)
      console.log('echoToken', this.echoToken)
      console.log('hotelSignature', this.hotelSignature)
      console.log('tpa_provider', this.hotel.RoomTypes[this.idQuarto].TPA_Extensions.Provider)
      console.log('tpa_signature', this.hotel.RoomTypes[this.idQuarto].TPA_Extensions.Signature)
      // this.form.nome = 'André'
      // this.form.sobrenome = 'Ortega'
      // this.form.nascimento = '1984-12-10'
      // this.form.cpf = '987.962.671-00'


      this.form.usuarioReserva = this.usuarios[0].id


      //const { data: users } = this.idUsuarioSelecionado = await this.usuarios[0].id

    }
  }
</script>